<template>
    <div class="index-container">
      <checkFilter ref="checkFilter" :filter="filter" status="Appoint">
        <el-button type="primary" @click="handleQuery()">查询</el-button>
      </checkFilter>
      <div class="tab-content">
        <checkList ref="checkList" :filter="filter" view="Test" />
      </div>
    </div>
  </template>
  <script>
  import checkList from "./components/checkList.vue";
  import checkFilter from "./components/checkFilter.vue";
  export default {
    components: { checkFilter, checkList },
    data () {
      return {
        filter: {
          status:'Create',
        },
      };
    },
    methods: {
      handleQuery () {
        let tempfilter = this.$refs.checkFilter.getfilter();
        tempfilter.status = "Create";
        this.filter = tempfilter;
      },
    },
    mounted () {
      this.handleQuery();
    },
  };
  </script>
  <style lang="scss" scoped>
  .index-container {
    .pagination {
      margin-top: 10px;
      text-align: right;
    }
    padding: 10px;
    .input {
      width: 150px;
    }
    .tabs-container {
      /deep/.el-tabs--border-card > .el-tabs__content {
        padding: 0;
        height: 1px;
      }
      .tab-content {
        margin-top: -2px;
        border: 1px solid #dcdfe6;
        border-top: 0;
        padding: 15px;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
      }
    }
  }
  </style>